<template>
  <div class="importVideo">
    <el-upload  class="avatar-uploader"
                action="#"
                :auto-upload="false"
                :on-change="onChange"
                :http-request="handleUpload"
                :on-progress="uploadVideoProcess"
                :show-file-list="false">
        <video  v-if="videoForm.showVideoPath !='' && !videoFlag"
                :src="videoForm.showVideoPath"
                class="video-avatar"
                controls
                muted>
            {{$t('message.audioSupport')}}
        </video>
        <i v-else-if="videoForm.showVideoPath =='' && !videoFlag"
            class="el-icon-plus avatar-uploader-icon"></i>
        <el-progress v-if="videoFlag === true"
                      type="circle"
                      :percentage="videoUploadPercent"
                      style="margin-top:7px;"></el-progress>
    </el-upload>
  </div>
</template>

<script>
import Utils from '@/utils/Utils'

export default {
  props: {
    value: {},
    object: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      videoFlag: false,
      // 是否显示进度条
      videoUploadPercent: '',
      // 进度条的进度，
      isShowUploadVideo: false,
      // 显示上传按钮
      videoForm: {
        showVideoPath: ''
      }
    }
  },
  created () {
    if (!Utils.isNull(this.value)) {
      this.videoForm.showVideoPath = this.value
    }
  },
  methods: {
    // 进度条
    uploadVideoProcess (event, file, fileList) {
      this.videoFlag = true
      this.videoUploadPercent = file.percentage.toFixed(0) * 1
    },
    onChange (event) {
      const uploadSize = (this.object.Object && this.object.Object.uploadSize) || 10
      const uploadType = (this.object.Object && this.object.Object.uploadType) || ['video/mp4', 'video/ogg', 'video/flv', 'video/avi', 'video/wmv', 'video/rmvb', 'video/mov']
      const fileSize = event.size / 1024 / 1024 < uploadSize
      if (uploadType.indexOf(event.raw.type) === -1) {
        this.$message.error(this.$t('message.uploadMessageType', { type: uploadType.join(',') }))
        return false
      }
      if (!fileSize) {
        this.$message.error(this.$t('message.uploadMessageSize', { num: uploadSize }))
        return false
      }
      let localUrl = null
      if (window.createObjectURL !== undefined) {
        // basic
        localUrl = window.createObjectURL(event.raw)
      } else if (window.URL !== undefined) {
        // mozilla(firefox)
        localUrl = window.URL.createObjectURL(event.raw)
      } else if (window.webkitURL !== undefined) {
        // webkit or chrome
        localUrl = window.webkitURL.createObjectURL(event.raw)
      }
      this.videoForm.showVideoPath = localUrl
      // console.log(localUrl)
      this.handleUpload(event)
    },
    handleUpload (event) {
      this.$emit('update:value', event.raw)
      this.$emit('isComplete', event)
    }
  }
}
</script>

<style lang="scss">
.importVideo {
  .avatar-uploader {
    .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .video-avatar {
    height: 178px;
    display: block;
  }
}
</style>
