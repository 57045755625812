/**
 * @name 广告栏
 */
import request from '@/utils/request'

export const getBannerSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/banners/setup'
  })
}

export const getbannersList = (params) => {
  return request({
    method: 'GET',
    url: '/cms/banners',
    params
  })
}

export const getbannersEditDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/banners/${id}/preview`
  })
}

export const getbannersDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/banners/${id}`
  })
}

export const getbannersCollections = () => {
  return request({
    method: 'GET',
    url: '/cms/banners/collections'
  })
}

export const deletebannersDetails = (id) => {
  return request({
    method: 'DELETE',
    url: `/cms/banners/${id}`
  })
}

export const postbannersFormData = (data) => {
  return request({
    method: 'POST',
    url: 'cms/banners',
    data
  })
}

export const putbannersFormData = (data, id) => {
  return request({
    method: 'PUT',
    url: `cms/banners/${id}`,
    data
  })
}

export const updateBannersOnline = (id) => {
  return request({
    method: 'POST',
    url: `cms/banners/${id}/online`
  })
}

export const updateBannersOffline = (id) => {
  return request({
    method: 'POST',
    url: `cms/banners/${id}/offline`
  })
}

/**
 * @name 弹窗管理
 */

export const getPopupsSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/popups/setup'
  })
}

export const getPopupsList = (params) => {
  return request({
    method: 'GET',
    url: '/cms/popups',
    params
  })
}

export const getPopupsEditDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/popups/${id}/preview`
  })
}

export const getPopupsCollections = () => {
  return request({
    method: 'GET',
    url: '/cms/popups/collections'
  })
}

export const getPopupsDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/popups/${id}`
  })
}

export const deletePopupsDetails = (id) => {
  return request({
    method: 'DELETE',
    url: `/cms/popups/${id}`
  })
}

export const postPopupsFormData = (data) => {
  return request({
    method: 'POST',
    url: 'cms/popups',
    data
  })
}

export const putPopupsFormData = (data, id) => {
  return request({
    method: 'PUT',
    url: `cms/popups/${id}`,
    data
  })
}

export const updatePopupsOnline = (id) => {
  return request({
    method: 'POST',
    url: `cms/popups/${id}/online`
  })
}

export const updatePopupsOffline = (id) => {
  return request({
    method: 'POST',
    url: `cms/popups/${id}/offline`
  })
}

/**
 * @name 导航栏
 */

export const getNavigationSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/navigation_buttons/setup'
  })
}

export const getNavigationList = (params) => {
  return request({
    method: 'GET',
    url: '/cms/navigation_buttons',
    params
  })
}

export const getNavigationEditDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/navigation_buttons/${id}/preview`
  })
}

export const getNavigationCollections = () => {
  return request({
    method: 'GET',
    url: '/cms/navigation_buttons/collections'
  })
}

export const getNavigationDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/navigation_buttons/${id}`
  })
}

export const deleteNavigationDetails = (id) => {
  return request({
    method: 'DELETE',
    url: `/cms/navigation_buttons/${id}`
  })
}

export const postNavigationFormData = (data) => {
  return request({
    method: 'POST',
    url: 'cms/navigation_buttons',
    data
  })
}

export const putNavigationFormData = (data, id) => {
  return request({
    method: 'PUT',
    url: `cms/navigation_buttons/${id}`,
    data
  })
}

export const updateNavigationOnline = (id) => {
  return request({
    method: 'POST',
    url: `cms/navigation_buttons/${id}/online`
  })
}

export const updateNavigationOffline = (id) => {
  return request({
    method: 'POST',
    url: `cms/navigation_buttons/${id}/offline`
  })
}

/**
 * @name 定制页面
 */

export const getPageSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/customized_landing_pages/setup'
  })
}

export const getPageList = (params) => {
  return request({
    method: 'GET',
    url: '/cms/customized_landing_pages',
    params
  })
}

export const getPageEditDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/customized_landing_pages/${id}/preview`
  })
}

export const getPageDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/customized_landing_pages/${id}`
  })
}

export const deletePageDetails = (id) => {
  return request({
    method: 'DELETE',
    url: `/cms/customized_landing_pages/${id}`
  })
}

export const postPageFormData = (data) => {
  return request({
    method: 'POST',
    url: 'cms/customized_landing_pages',
    data
  })
}

export const putPageFormData = (data, id) => {
  return request({
    method: 'PUT',
    url: `cms/customized_landing_pages/${id}`,
    data
  })
}

export const updatePageOnline = (id) => {
  return request({
    method: 'POST',
    url: `cms/customized_landing_pages/${id}/online`
  })
}

export const updatePageOffline = (id) => {
  return request({
    method: 'POST',
    url: `cms/customized_landing_pages/${id}/offline`
  })
}

export const getPageCollections = () => {
  return request({
    method: 'GET',
    url: '/cms/customized_landing_pages/collections'
  })
}

export const getHotSearchSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/shop_search_words/setup'
  })
}

export const getHotSearchList = (params) => {
  return request({
    method: 'GET',
    url: '/cms/shop_search_words',
    params
  })
}

export const deleteHostSearch = (id) => {
  return request({
    method: 'DELETE',
    url: `/cms/shop_search_words/${id}`
  })
}

export const postHostSearch = (data) => {
  return request({
    method: 'POST',
    url: 'cms/shop_search_words',
    data
  })
}

export const getHotSearchPriview = (id) => {
  return request({
    method: 'GET',
    url: `/cms/shop_search_words/${id}/preview`
  })
}

export const putHotSearch = (id, data) => {
  return request({
    method: 'PUT',
    url: `cms/shop_search_words/${id}`,
    data
  })
}
export const getHotSearchDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/shop_search_words/${id}`
  })
}
